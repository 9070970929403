
































import { Component, Mixins } from 'vue-property-decorator';

import Loader from '@/components/UI/Loader.vue';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

@Component({
  components: {
    Loader,
  },
})
export default class Unsubscribe extends Mixins(View) {
  private loading = true;

  private invalid = false;

  private error = false;

  private email: string | null = null;

  private optedOut = false;

  mounted() {
    const contactId = this.$route.query?.ContactId;
    const userId = this.$route.query?.UserId;

    if (contactId && contactId.length && userId && userId.length && typeof contactId === 'string' && typeof userId === 'string') {
      API.CRM.GetOptoutStatus(contactId, userId).then((status) => {
        this.email = status.Email;
        this.optedOut = status.OptedOut;
        this.loading = false;
      }).catch(() => {
        this.error = true;
        this.loading = false;
      });
    } else {
      this.invalid = true;
      this.loading = false;
    }
  }

  optOut() {
    const contactId = this.$route.query?.ContactId;
    const userId = this.$route.query?.UserId;

    this.loading = true;

    if (contactId && contactId.length && userId && userId.length && typeof contactId === 'string' && typeof userId === 'string') {
      API.CRM.OptOut(contactId, userId).then((data) => {
        this.loading = false;

        this.optedOut = data.OptedOut;
      }).catch(() => {
        this.loading = false;
        this.error = true;
      });
    }
  }
}
